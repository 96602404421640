








































#title, #nav {
  border-bottom: 1px solid #ccc;
}

#header{
  display: grid;
  grid-template-columns: 61px 260px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "icon title nav"
    "icon subtitle subnav";
  border-bottom: 1px solid #ccc;
  height: 60px;
}
#header> img {
  grid-area: icon;
  height: 48px;
  align-self: center;
  justify-self: center;
}
#header > h1 {
  grid-area: title;
  align-self: end;
  font-weight: 300;
  font-size: 23px;
  line-height: 1;
  > b {
    font-weight: 600;
  }
}
#header > span {
  grid-area: subtitle;
  align-self: center;
}

#header > #nav {
  grid-area: nav;
  display: flex;
  justify-content: flex-start;
  align-self: end;
  > button {
    margin: 0;
  }
}

#header > #subnav {
  border-left: 1px solid #ccc;
  box-shadow: inset 5px 5px 11px -10px rgba(0,0,0,0.46);
  > a {
    display: block;
  }
}
